// note: all tokens called 'token', socketio-jwt restriction
function socketIOFactory(socketFactory, SETTINGS, Auth, userTokens,
  $rootScope, $log, $q, $interval, $timeout) {
  const socketIO = {
    socket: null,
    eventPrefix: 'sq.services.socketIO:',
  };

  const eventPrefix = socketIO.eventPrefix;

  let connecting = false;
  let refreshTimeout = null;
  let authAttempts = 0;
  // { socket : {}, loggedIn : false};
  // for now, only allow one socket, instead join multiple rooms socketIO.sockets = [];

  let connectTimeout;
  socketIO.connect = function socketConnectFn() {
    if (connectTimeout) {
      $timeout.cancel(connectTimeout);
    }

    connectTimeout = $timeout(() => {
      if (socketIO.socket && socketIO.socket.connected) {
        _logOut.call(socketIO.socket);
      }

      socketIO.socket = _newConnection();
      return $q.resolve(socketIO.socket);
    }, 0, false);
    // // return $q.resolve(_newConnection());
    // var deferred = $q.defer();
    // // singleton
    // console.info('socketIO.socket', socketIO.socket, connecting);
    // if ((!socketIO.socket || !socketIO.socket.connected) && !connecting) {
    //   socketIO.socket = _newConnection();
    //   if (!socketIO.socket) {
    //     deferred.reject(new Error('unable to create socket.io socket') );
    //   } else {
    //     deferred.resolve(socketIO.socket); // instead, just reference Auth`
    //   }
    // } else {
    //   deferred.resolve(socketIO.socket);
    // }
    // return deferred.promise;
  };

  $rootScope.$on(Auth.eventPrefix + 'loggedIn', function logInCb() {
    $log.debug('got sq.user.auth:loggedIn event in services.socketIO.js');
    socketIO.connect();
  });

  $rootScope.$on(Auth.eventPrefix + 'loggedOut', function logOutCb() {
    $log.debug('received sq.user.auth:loggedOut in socketio');
    if (socketIO.socket && socketIO.socket.connected) {
      _logOut.call(socketIO.socket);
    } else {
      $timeout.cancel(refreshTimeout);
    }
  });

  return socketIO;

  /* @private*/

  function _newConnection() {
    if (!userTokens.idToken) {
      $log.error('SocketIO requires idToken');
      return null;
    }

    var socket = socketFactory({
      ioSocket: io.connect(SETTINGS.socketIOurl, {
        rejectUnauthorized: true,
        secure: true,
      }),
      prefix: eventPrefix,
    });

    console.info('socketIO', socket);

    socket.connected = true;

    _clearAuthAttempts.call(socket);
    _createAuthListener.call(socket);

    return socket;
  }

  // TODO: consider merits of just using Auth.token directly,
  // to avoid needing to tear down the listeners
  function _createAuthListener() {
    var self = this;

    //events for which we don't need to give access to the socket
    //this has a nasty side effect of triggering an $apply, becuase of 
    //use of asyncAngularify
    self.forward(['connect', 'connect_error',
      'reconnect_attempt', 'reconnect_failed']);

    // 'connect' event is also triggered when reconnecting
    self.on('connect', function onConnectCb() {
      _authenticate.call(self);
    });

    self.on('reconnect_failed', function () {
      _logOut.call(self);
    });

    self.on('unauthorized', function onUnauthCb() {
      //just to make clear that we're broadcasting the socket and not the wrapper
      var socket = this;
      //not forwarding because I want to expose the socket
      $rootScope.$broadcast(eventPrefix + 'unauthorized', socket);

      if (authAttempts > 20) {
        _logOut.call(self);
        return;
      }

      if (refreshTimeout) {
        return;
      }

      userTokens.refreshIdTokenAsync().then(() => {
        $log.debug('calling authenticate after userTokens.refreshIdTokenAsync in services.socketIO.js');
        _authenticate.call(self);
      }, () => {
        refreshTimeout = $timeout((socketWrapped) => {
          _authenticate.call(socketWrapped);
          refreshTimeout = null;
        }, 5000, false, self);
      });
    });

    self.on('authenticated', function () {
      var socket = this;
      //not forwarding because I want to expose the socket
      $rootScope.$broadcast(eventPrefix + 'authenticated', socket);
      _clearAuthAttempts.call(self);
    });
  }

  function _clearAuthAttempts() {
    authAttempts = 0;
    if (refreshTimeout) {
      //$timeout.flush(refreshTimeout);
      $timeout.cancel(refreshTimeout);
    }
  }

  //TODO: rethink this;
  //Will have Error: this is null if unable to create socket first
  function _connect() {
    if (this.connected) {
      return;
    }

    $log.debug('connecting this in logIn', this);
    _clearAuthAttempts.call(this);
    _createAuthListener.call(this);
    this.connect();
    this.connected = true;
  }

  function _logOut() {
    if (connectTimeout) {
      $timeout.cancel(connectTimeout);
    }

    connectTimeout = $timeout(() => {
      $log.debug('this in _logOut', this);
      this.disconnect(); //calls remove all listeners
      this.removeAllListeners();

      this.connected = false;
      connecting = false;
      //this.authenticated = true;

      _clearAuthAttempts.call(this);
      $rootScope.$broadcast(eventPrefix + 'disconnected', this);
    }, 0, false);
  }

  function _authenticate() {
    ++authAttempts;
    this.emit('authenticate', { token: userTokens.idToken });
  }
}

angular.module('sq.services.socketIO', ['btford.socket-io', 'sq.config',
  'sq.user.auth', 'sq.user.auth.tokens']).factory('socketIO', socketIOFactory);
